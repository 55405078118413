import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { books } from "./Books"
import Book from "./Book"

const BookList = () => {
  return (
    <React.Fragment>
      <h1>domanjies book store</h1>
      <section className="bookList">
        {books.map((book, index) => {
          return <Book {...book} key={book.id} number={index} />
        })}
      </section>
    </React.Fragment>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<BookList />)
