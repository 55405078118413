import img1 from "./images/book-1.jpg"
import img2 from "./images/book-4.jpg"
import img3 from "./images/book-3.jpg"
import img4 from "./images/book-2.jpg"
export const books = [
  {
    author: "jordan Moore",
    title: "Interesting Facts for curios Mindssss",
    img: img1,
    id: 1,
  },
  {
    author: "Kai Bird",
    title:
      "American Prometheus: The Inspiration for the Major Motion Picture OPPENHEIMER",
    img: img2,
    id: 2,
  },
  {
    author: "ilah mervyn",
    title: "Red White & Royal Blue",
    img: img3,
    id: 3,
  },
  {
    author: "Domanjie Ehinomen",
    title: "lessons in chemistry",
    img: img4,
    id: 4,
  },
]
